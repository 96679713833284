﻿/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

/* iphone X , XS, 11 Pro, 12 Mini */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

/* iphone 12, 12 Pro */
@media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

/* iphone XR, 11 */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

/* iphone XS Max, 11 Pro Max */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

/* iphone 12 Pro Max */
@media only screen and (min-device-width : 428px) and (max-device-height : 926px) and (-webkit-device-pixel-ratio : 3) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

/* all androids */

@media only screen and (min-device-width: 360px) and (max-device-height: 740px) and (-webkit-device-pixel-ratio: 4) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

@media only screen and (min-device-width: 411px) and (max-device-height: 731px) and (-webkit-device-pixel-ratio: 2) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

@media only screen and (min-device-width: 411px) and (max-device-height: 731px) and (-webkit-device-pixel-ratio: 3) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

@media only screen and (min-device-width: 411px) and (max-device-height: 823px) and (-webkit-device-pixel-ratio: 1) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

@media only screen and (min-device-width: 411px) and (max-device-height: 823px) and (-webkit-device-pixel-ratio: 2) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

@media only screen and (min-device-width: 411px) and (max-device-height: 823px) and (-webkit-device-pixel-ratio: 3) {
	body {
		.hero-container {
			overflow: hidden;
		}
	}
}

.hero-container {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 556px;
	background: linear-gradient( rgba(18, 26, 66, 0.45), rgba(18, 26, 66, 0.45) ), url(/Assets/images/header_v1.png);
	background: linear-gradient( rgba(18, 26, 66, 0.45), rgba(18, 26, 66, 0.45) ), var(--bgImgUrl);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 556px;
	overflow: hidden;



	&--video {
		&.loading .video-background {
			opacity: 0;
		}

		.video-background {
			position: absolute;
			top: 50%;
			left: 0;
			padding-top: 56.25%;
			width: 100%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			-webkit-transition: 2s opacity ease;
			transition: 2s opacity ease;
			opacity: 1;
		}


		.video-foreground,
		.video-background iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}
	}
}

.hero-content {
	position: relative;
	margin: auto;
	max-width: 62.6rem;
	text-align: center;
	top: 40%;
	color: #fff;
	padding: 0 1rem;
	z-index: 100;

	h2 {
		font-size: 4rem;

		@include media(">=tablet") {
			font-size: 4.5rem;
		}
	}

	p{
		margin-bottom: 3rem;
	}

	&--alt {
		position: relative;
		margin: auto;
		max-width: 62.6rem;
		text-align: center;
		top: 40%;
		color: #fff;

		h2 {
			color: $orange;
			font-family: Montserrat;
			font-size: 4rem;

			@include media(">=tablet") {
				font-size: 4.5rem;
			}
		}
	}
}

.hero-section {
	background-color: #fff;
	height: 75rem;

	.hero-background {
		padding-right: 0;
		background-color: #000;

		img {
			width: 100%;
			height: 100%;
		}

		h1 {
			color: #fff;
			max-width: 40rem;
			margin: 17rem auto 24rem;
		}
	}

	@include media(">=tablet") {
		max-height: 53.5rem;
	}
}

.header-content {
	min-width: 420px;
	position: relative;
	top: 3rem;
	margin: auto;

	&--right {
		max-width: 420px;
		position: relative;
		margin-left: 4rem;

		@include media(">=tablet") {
			top: 14rem;
		}
	}

	&--about-right {
		max-width: 705px;
		position: relative;
		top: 0;
		margin-left: 1rem;
		padding-bottom: 3rem;

		@include media(">=tablet") {
			top: 3rem;
			margin-left: 4rem;
		}

		li {

			p {
				margin: 0;
			}
		}

		h4 {
			margin-bottom: 1rem;
		}

		.button-productdetail {
			font-size: 1.6rem;
			cursor: pointer;
			font-weight: bold;
			text-decoration: none;
			border: 1px solid transparent;

			&:after {
				content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAYAAACkTj4ZAAAABHNCSVQICAgIfAhkiAAAAJ5JREFUKFNjZCASCIg6GDCyMu7///u/44fXBy6ga2Mk0hywMiEpxwX/GRj8sRlGkkH4DCPZIFyGkWUQNsMYQYHIwMzIT0pYwdQyMjFMYGBgUACFGaOglOMFRgZGfXIMguv5zzCRbK/BkgMjA8PGd8/2J5BlELohIJeRbBA2Q0g2CJchJBkkIOGgwMjEeB4WJhRlEQEJp4QPL/YtwBbDAHaqUKBJZOeSAAAAAElFTkSuQmCC');
				width: 30px;
				height: 10px;
				padding-left: 3rem;

				@include media(">=tablet") {
					padding-left: 5rem;
				}
			}

			&.products-anchor {
				color: $dark-blue;
				position: relative;
				text-align: left;
				background-color: transparent;
				padding: 1.2rem 1.5rem 1.2rem 0;
				margin-right: 2rem;

				@include media(">=tablet") {
					padding: 1.2rem 3rem;
				}
			}

			&.contact-cta {
				position: relative;
				height: 55px;
				width: 200px;
				border: none;
				text-align: left;
				color: $dark-blue;
				background-color: transparent;
				padding-left: 30px;
				padding: 1.2rem 1.5rem 1.2rem 0;
			}

			&:hover {
				border: 1px solid $orange;
			}
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		p {
			margin-bottom: 2rem;
		}
	}
}
