.search-results-section {
	margin: auto;

	@include media(">=tablet") {
		margin: 2rem auto;
		min-height: 65rem;
		justify-content: center;
		flex-wrap: wrap;
	}
}

.search-results-container {
	min-height: 62rem;

	.search-results-content {
		background-color: $dark-blue;
		min-height: 62rem;
		padding-bottom: 2.5rem;

		img {
			width: 100%;
			height: auto;
		}
	}

	.search-result-items {
		width: 75%;
		margin: auto;

		ul {
			list-style: none;
			padding: 0;
			font-size: 1.6rem;
			font-weight: bold;
		}

		a {
			color: $light-blue;
			text-decoration: none;
		}

		a:hover {
			color: $orange;
		}
	}

	.search-result-summary {
		width: 75%;
		margin: auto;
		text-align: center;

		a {
			&.page{
				color: $white;
				text-decoration: none;
				width: 32px;
				padding: 10px;
				margin: 10px 0;
				text-align: center;
				display: inline-block;
				cursor: pointer;
				font-size: 1.3rem;
			}

			&.active {
				border: 1px solid $orange;
			}
		}

		a:hover {
			color: $orange;
		}
	}
}

.search-headline {
	color: $orange;
	margin: auto;
	width: 75%;
	padding-top: 2rem;
	font-weight: 300;

	h2 {
		font-weight: 300;
	}

	form {
		padding: 0;
	}
}

.search-headline-new {
	color: $orange;
	width: 100%;
	font-weight: 300;
	padding-left: 13px;

	h2 {
		font-weight: 300;
	}

	form {
		padding: 0;
	}
}

@include media(">=tablet") {

	.search-results-container {
		min-height: 66rem;

		.search-results-content {
			background-color: $dark-blue;
			min-height: 75rem;
			position: relative;
			min-width: 34rem;
			padding-bottom: 1.5rem;

			img {
				width: 100%;
				height: auto;
			}

			&--short {
				background-color: $dark-blue;
				min-height: 54rem;
				position: relative;
				min-width: 34rem;

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.search-result-items {

			ul {
				list-style: none;
				padding: 0;
				font-size: 1.6rem;
				font-weight: bold;
			}

			h5 {
				margin-bottom: 1rem;
			}

			h4 {
				margin-top: 2rem;
			}
		}
	}
}