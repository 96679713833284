﻿.callout-container {
	background-image: url("/Assets/images/callout.png");
	background-repeat: no-repeat;
	background-size: cover;
	min-width: 100%;
	height: 556px;

	&--remeshing {
		background: radial-gradient( rgba(18, 26, 66, 0.90), rgba(18, 26, 66, 0.90) ), url(/Assets/images/remeshing-callout.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		min-width: 100%;
		height: 556px;

		.callout-content {
			position: relative;
			margin: auto;
			max-width: 62.6rem;
			text-align: center;
			top: 30%;
			color: #fff;

			h2 {
				color: $light-blue;
			}

			p {
				margin-bottom: 4rem;
			}
		}
	}

	.callout-content {
		position: relative;
		margin: auto;
		max-width: 62.6rem;
		min-width: 48rem;
		text-align: center;
		top: 30%;
		color: #fff;

		h2 {
			color: $light-blue;
		}

		p {
			margin-bottom: 4rem;
		}
	}
}

.optin-section {
	min-width: 100%;
	color: #fff;
	background-color: $dark-blue;
	padding-top: 2rem;
	padding-bottom: 1.5rem;

	.optin-headline {
		text-align: center;
	}

	.optin-subheadline {
		text-align: center;
	}

	.umbraco-forms-submitmessage {
		color: $white;
		font-size: 1.6rem;
		text-align: center;

		@include media(">=desktop") {
			padding: 5rem 10rem;
			display: block;
		}

		padding: 5rem 5rem;
		display: block;
	}
}

.email-container {

	@include media(">=tablet") {
		width: 100%;
		display: inline-flex;
		min-height: 18rem;
	}

	.email-content-container {
		background-color: #000729;
		position: relative;

		.email-content {
			width: 75%;
			margin: auto;
			position: relative;
			color: #fff;

			h4 {
				margin-bottom: 0;
			}

			@include media(">=tablet") {
				width: 55%;

				h4 {
					margin-bottom: 3.5rem;
				}
			}
		}
	}

	.email-button-container {
		background-color: $dark-blue;

		.umbraco-forms-submitmessage {
			color: $white;
			font-size: 1.6rem;
			padding-left: 7rem;

			@include media(">=desktop") {
				padding: 5rem 10rem 5rem 24rem;
				display: block;
			}
		}
	}
}