﻿/* Style the tab content */
.tab-content {
	display: none;
	padding: 1rem 0;

	&.show {
		display: block;

		.umbraco-forms-submitmessage {
			color: $white;
			font-size: 1.6rem;
			display: block;
			padding: 4rem 0;
		}
	}
}

.tab-section {
	margin-top: 5rem;

	.tab-rte {
		padding: 4rem;;
	}

	.tab-buttons {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 100%;
		max-width: 920px;

		&__multiform {
			max-width: 100%;
			border-bottom: 1px solid $orange;
		}

		@include media(">=tablet"){
			margin-left: 4rem;
		}

		.tab-button {
			position: relative;
			flex: 1 1 33%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;
			align-items: center;
			background-color: $dark-blue;
			cursor: pointer;
			height: 13rem;
			transition: 0s;
			color: #fff;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 1.6rem;
			text-align: center;

			&__multiform {
				height: 5rem;
				max-width: 26rem;
			}

			&.active:before,
			&:hover:before {
				content: "";
				display: block;
				width: 0;
				height: 0;
				position: absolute;
				bottom: -1.6rem;
				left: 0;
				right: 0;
				margin: auto;
				border-style: solid;
				border-width: 1.6rem 1.6rem 0;
				border-color: $light-blue transparent transparent transparent;
			}

			> img {
				display: none;
				margin-bottom: 1.6rem;
				max-width: 4.2rem;
				height: auto;

				@include media(">=tablet") {
					display: block;
				}
			}

			&:hover,
			&.active {
				background-color: $light-blue;
			}
		}
	}
}

.form-tab {
	background-color: $dark-blue;
	float: left;
	border: none;
	outline: none;
	cursor: pointer;
	height: 4rem;
	width: 15rem;
	transition: 0s;
	color: #fff;

	&.active {
		background-color: $light-blue;
	}
}

.multi-form-header {
	.hero-background {
		display: none;

		&.show {
			display: block;
		}
	}
}