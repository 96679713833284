﻿.alt-section {
	background-color: #061433;
}

.product-section {
	min-width: 100%;
	color: #fff;

	.product-card-container {
		display: flex;
		flex-direction: column;
	}

	.product-card-content {
		margin: auto;
		background-color: $dark-blue;
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;

		&--card {
			background-color: $dark-blue;
			flex-grow: 1;
			padding: 2rem;
			display: flex;
			flex-direction: column;
			height: 100%;

			.details-button {
				margin-top: auto;
				display: flex;
				min-width: 80%;
			}
		}

		&__body {
			box-sizing: border-box;
			width: 100%;
		}

		img {
			width: 100%;
			height: auto;
		}

		.button {
			width: 80%;
		}

		.product-card-summary {
			margin: auto;
			padding: 0px 2rem 2rem;
			display: flex;
			flex-direction: column;
			height: 100%;

			h4 {
				margin: 0;

				@include media(">=tablet") {
					margin-bottom: 1rem;
					margin-top: 2rem;
				}
			}
		}
	}

	.product-label {
		color: #59C9FC;

		&--alt {
			color: #F08C5E;

			a {
				color: inherit;
				text-decoration: none;
			}
		}

		a {
			color: inherit;
			text-decoration: none;
		}
	}


	.product-card-section {
		justify-content: center;
		flex-wrap: wrap;
		margin: auto;
	}

	.product-card-subheadline {
		text-align: center;
	}

	.product-card-headline {
		text-align: center;
		color: #59C9FC;

		&--category {
			color: #F08C5E;
			text-align: center;
			margin-bottom: 1rem;
		}

		&--products {
			color: #59C9FC;
			text-align: center;
		}
	}

	@include media(">=tablet") {

		.product-card-container {
			position: relative;
		}

		.product-label {
			color: #59C9FC;

			&--alt {
				color: #F08C5E;

				a {
					color: inherit;
					text-decoration: none;
				}
			}

			a {
				color: inherit;
				text-decoration: none;
			}
		}

		.product-card-section {
			margin: 2rem auto;
			justify-content: left;

			&--headline {
				margin: 0 auto;
				padding: 2rem 0;
			}
		}

		.product-card-subheadline {
			text-align: center;
		}

		.product-card-summary {
			padding: 0px 2rem 3rem 4rem;

			h4 {
				margin-bottom: 1rem;
				margin-top: 2rem;
			}

			h5 {
				margin-bottom: 1rem;
			}

			p {
				margin-bottom: 3rem;
			}

			.details-button {
				margin-top: auto;
				display: flex;
			}

			&--headline {
				display: flex;
				flex-direction: column;
				min-height: 36.5rem;
				padding: 2rem 5rem;
			}

			&--split-cards {
				min-height: 36.5rem;
				padding: 2rem 0 0rem 3rem;

				p {
					margin-bottom: 3rem;
				}
			}
		}
	}
}

.products-page-section {
	background-color: #fff;

	.product-label {
		color: #59C9FC;
	}

	.product-headline {
		color: #F08C5E;

		&--alt {
			color: #F08C5E;
			font-weight: 300;
			font-size: 3.6rem;
			
			@include media(">=tablet"){
				font-size: 4.5rem;
			}
		}
	}

	.about-content {
		max-width: 420px;
		position: relative;
		top: 3rem;
		margin: auto;

		&--right {
			position: relative;
			margin-left: 4rem;

			a {
				color: inherit;
				text-decoration: none;
			}
		}

		&--about-right {
			min-width: inherit;
			padding: 1rem;
			position: relative;
			top: 3rem;
			margin-left: 0;

			@include media(">=tablet") {
				min-width: 420px;
				padding: 0;
				margin-left: 4rem;
			}

			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}

	img {
		width: 100%;
		height: 100%;
	}

	.about-background {
		padding: 0;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}

		about-section {
			min-height: 50rem;
		}
	}
}

.product-summary {
	color: #fff;
	min-width: 52%;
	position: relative;
	margin-top: 4rem;

	h2 {
		color: #59C9FC;
	}

	p {
		margin-bottom: 4rem;
	}
}

@include media(">=tablet") {
	.products-page-section {
		background-color: #fff;
		margin-bottom: .7rem;

		.product-label {
			color: #59C9FC;
			text-decoration: none;
		}

		.about-section {
			padding: 0;

			.about-content {
				width: 420px;
				position: relative;
				top: 3rem;

				&--about-left {
					position: relative;
					position: relative;
					margin: 0 auto;
					padding: 2rem 4rem;

					a {
						color: inherit;
						text-decoration: none;
					}
				}
			}
		}

		.product-headline {
			color: #F08C5E;
		}

		img {
			width: 100%;
			height: 100%;
		}

		.about-background {
			padding-top: 0;
			padding-right: 0;
			background-color: #000;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}