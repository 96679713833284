﻿.form-section{
    background-color: #000729;
	padding-top: 1rem;

	.rte {
		color: $white;
	}

	.tab-section .tab-buttons .tab-button {
		flex-grow: 0;
		flex-basis: 35%;
	}
}

/*second version*/

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: #379937;
}

.dropdowns {
	padding-left: 5px;
	padding-bottom: 2%;

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		background: #030338;
		border-radius: 10px;
	}
}

/* Selected tags */

.schema-tags {
	margin-right: 195px;
	padding: 0px 15px 0px 6px;
	position: relative;
	font-size: 0;
}

.schema-tags__item {
	margin: 0 5px 5px 0;
	max-width: 100%;
	display: inline-block;
	white-space: nowrap;
	position: relative;
	overflow: hidden;
	font-size: 13px;
	line-height: 1.5;
	vertical-align: top;
	cursor: pointer;
	background: #5d5d5d;
	border-radius: 2px;
	-o-transition: background-color .1s ease-in-out;
	transition: background-color .1s ease-in-out;
}

.schema-tags__text {
	padding: 4px 20px 4px 10px;
	position: relative;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.schema-tags__item::after {
	content: "×";
	opacity: 0.5;
	position: absolute;
	top: 3px;
	right: 5px;
	font-size: 15px;
	speak: none;
}

.schema-tags__item:hover .schema-tags__text {
	text-decoration: line-through;
}



.dropdown-new {
	position: relative;
	font-size: 14px;
	color: white;
	background-color: #092a48;
	z-index: 10;
	display: inline-block;
	height: 45px;
	padding-top: 9px;
	margin-right: 15px;

	&.short-width {
		width: 90px;
	}

	&.long-width {
		width: 160px;
	}

	.dropdown-list-new {
		padding: 12px;
		background: #fff;
		position: absolute;
		top: 45px;
		left: 0px;
		right: 2px;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .15);
		transform-origin: 50% 0;
		transform: scale(1, 0);
		transition: transform .15s ease-in-out .15s;
		max-height: 66vh;
		overflow-y: scroll;
		width: 213px;
		background-color: #092a48;

		&.two-rows {
			height: 90px;
		}

		&.many-rows {
			height: 184px;
		}
	}

	.dropdown-option-new {
		display: block;
		padding: 8px 12px;
		opacity: 0;
		transition: opacity .15s ease-in-out;

		&:hover {
			background-color: #0c3f6e;
		}
	}

	.dropdown-label-new {
		display: block;
		height: 30px;
		background-color: #092a48;
		padding: 6px 12px;
		line-height: 1;
		cursor: pointer;

		&:before {
			content: '▼';
			float: right;
		}
	}

	&.on {
		.dropdown-list-new {
			transform: scale(1, 1);
			transition-delay: 0s;

			.dropdown-option-new {
				opacity: 1;
				transition-delay: .2s;
			}
		}

		.dropdown-label-new:before {
			content: '▲';
		}
	}

	[type="checkbox"] {
		position: relative;
		top: -1px;
		margin-right: 4px;
	}
}


form {
	top: 7%;
	color: #fff;
	padding: 0;

	@include media(">=tablet") {
		padding: 4rem;
	}

	fieldset {
		border: none;
		padding: 0;
	}

	.umbraco-forms-page {
		.row-fluid {
			.content-library-form-container-left {
				padding: 0px 0px 0px 0px;
				width: 90%;
			}

			.content-library-form-container-right {
				padding: 0px 0px 0px 0px;
				width: 10%;
			}
		}
	}

	.form-label {
		color: #fff;
		font-size: 15px;
		text-transform: uppercase;
	}

	.form-input {
		color: #fff;
		background-color: #000729;
		border: 1px solid $orange;
		border-radius: 0;
		outline: none;
		font-size: 1.6rem;

		&::placeholder {
			color: $white;
		}

		select {
			background-color: transparent;
			border: none;
			color: #ef5d15;
			height: 100%;
			width: 100%;
			cursor: pointer;
		}
	}

	.content-library-input {
		height: 4em;
	}

	.form-field {
		margin: 25px 0;
	}

	.form-field.checkbox {
		display: flex;
		align-items: center;

		.umbraco-forms-label {
			order: 2;
			margin-bottom: 3px;
		}

		.umbraco-forms-field-wrapper {
			order: 1;
		}
	}

	input[type='checkbox'] {
		margin-top: -30px;
	}

	input[type='checkbox'] {
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeSpeed;
		min-width: 13px;
		min-height: 13px;
		margin: 0;
		margin-right: 15px;
		position: relative;
		cursor: pointer;
	}

	input[type='checkbox']:after {
		content: "";
		vertical-align: middle;
		text-align: center;
		line-height: 13px;
		position: absolute;
		cursor: pointer;
		height: 13px;
		width: 13px;
		left: 0;
		top: 0;
		font-size: 10px;
		-webkit-box-shadow: inset 0 1px 1px #000729, 0 1px 0 #000729;
		-moz-box-shadow: inset 0 1px 1px #000729, 0 1px 0 #000729;
		box-shadow: inset 0 1px 1px #000729, 0 1px 0 #000729;
		background: #000729;
		outline: 1px solid $orange;
	}


	input[type='checkbox']:checked:after {
		background: #000729;
		content: '\2714';
		color: #fff;
		outline: 1px solid $orange;
	}

	input[type='file'] {
		display: none;
	}

	.form-left {
		width: 50%;
		margin-right: 30px;
	}

	.form-right {
		width: 50%;
	}

	.form-quarter {
		width: 25%;
	}

	.dropdown-quarter {
		width: 100%;
		border-radius: 0;
	}

	.form-quarter:nth-child(-n+3) {
		margin-right: 30px
	}

	.form-text-area {
		width: 100%;
	}

	.submit-button {
		position: relative;
		color: #fff;
		font-size: 1.5rem;
		background-color: #707070;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		padding: 22px 45px 22px 15px;
		cursor: pointer;
		border: 1px solid transparent;

		&:after {
			position: absolute;
			content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAYAAACkTj4ZAAAABHNCSVQICAgIfAhkiAAAAKRJREFUKFNjZCASvO+KMmBkYtn//98fR8GyZRfQtTESaQ5Y2fueuAWMDAz+2AwjySB8hpFsEC7DyDIIm2GMoEBkYWHmJyWsYGr//mWcwMDIoAAKM8b3PbEXGBkY9ckxCKbnPwPDRPK9BksODAwbBUsWJZBlEDxNQQ0BuYxkg7AZQrJBuAwhyaD3/QkKjH//nf+P5B3kCCLJa+97YxMEixcvwBbDAJTzWHqyOA0SAAAAAElFTkSuQmCC');
			width: 30px;
			height: 10px;
			top: 0;
			bottom: 0;
			right: 5px;
			margin: auto;
		}

		&:hover:after {
			content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAAq0lEQVQ4jcWUuwrCQBBFT3xgfFUi+Bf+tQiWgihYaWNh7RfYWFppIRxZ3IBgBBMjXrjNMnO4y84sahEP1Zu6UtO8vqLAAJn70FZtfwsMrqvLCH1JWgYY3FBnEbp5TloWmHkSoSFxLwOGyF21VdCJ2lSnEbpXB+HwAIyAC5DwuULtGbgC49i1qwEnIAUsAHuvGL1f1ZV/8ih/H5tKB7ujLqpcvQA8quvcz0G5A7oT9DYx6XLOAAAAAElFTkSuQmCC');
		}
	}
}

	.form-container {
		width: 70%;
		position: relative;
		margin: auto;
	}

	.umbraco-forms-form.emailoptin {
		width: 75%;
		margin: 0 auto;
		padding-bottom: 2rem;

		@include media(">=tablet") {
			width: 50%;
			padding-top: 0;
		}
	}

	.field-validation-error {
		margin: 25px 0;
		color: $red;
	}