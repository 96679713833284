﻿.site-footer {
	background-color: $dark-blue;
	color: #fff;
	font-weight: bold;
	width: 100%;
	font-size: 1.5rem;
	text-align: center;

	.phone {
		padding-left: 5rem;
		color: #fff;
		margin: 2rem 0 2rem -3.2rem;
		text-decoration: none;

		&:before {
			display: inline-block;
			content: " ";
			width: 2.2rem;
			height: 2.4rem;
			margin-right: 1rem;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAbCAYAAACa9mScAAAABHNCSVQICAgIfAhkiAAAAORJREFUOE/tleENAUEQhb/XARWgAkrQASqgA3RABZRABXQgOlCCEnTwZC53OC5ixR/JTXK5vezsy87L3Dey3QN2QJv0OAMj2Y7FRtLiUcN2Ixe+AC1Jx4r9GTAIEQNNSZF8C9sTIJ5T/m5X5PSBQyFSukVCVWHBpBBZJhx8TA2RcSYiSd+I2L6XU4tkFtaevHZS7cnfeAL8BEolfiawJTjcfQsl22tgCiyfQf7xD5hDfA6sqsBV6ligIylGRyls72MkAFtJQf7n/cDjPsrZRF3xkeBFkToEjhmgbccQCpNS4yJpfQU02+A3uYImUwAAAABJRU5ErkJggg==');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			vertical-align: middle;
		}

		@include media(">=desktop") {
			color: $orange;
			padding-left: 4rem;
			margin: 0 0 0 -1rem;

			&:hover {

				&:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
				}
			}

			&:before {
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
			}
		}
	}

	.footer-section--icons { //<-garbage class name, BTW
		a {
			padding-left: 2rem;
			color: blue;
			margin: 2rem 0 2rem -3.2rem;
			text-decoration: none;

			&:before {
				display: inline-block;
				content: " ";
				width: 3rem;
				height: 3rem;
				margin-right: 1rem;
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				vertical-align: middle;
			}

			&.twitter:before {
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAvBJREFUSEu9V9Fx2kAQfSucZCY/4SczQGLAFQRXEFyBnQpiV+BTBSEVcK7ApAKTCowrMFQQgRNgJh/BP55JDLeZOyRZIOkkeTzWp7R7b9/u3tsVIedTeS/a5OAQhBYBZYBaa1ceMrAAY7hc4dvvX3KY50iyGZWbovxa4RQEAVA5z4EM9sAkZ5Pumc0+Fbi2K47g4Dwv4DaIDoAU3OmN7CcFkAhcrbuSCKd5GGbZMEMksY8B1+puD4TPWQcW+s7cm07kSdRnA/gpmcZSz+zOJlIG70PgdU3pohCTgsZqxQfzn3Kg3Qyw6V7Gj0c20i0zetDdDGoS4RjAG3PRgDEBjSA+3XCzsdwLgWt10QHRlygBBq6I4WXUe3RH3F54chH4+ldQ3K/Q3ylRhwiH0XOV4pP5jewZxrWGqx1NlOHD/HU6kR1bs6m/vDefSy8p46Z0hFac0Jo1aUVySnQZc/aB9XujWiXSUUbShqvZuNtOK7NfvssHhXuw1AFTWiczeDAby4PowZoFEx0bJoBnA04qX1hrZpeqDXdAwMeEyG+VYqHrUbB5jbnlXDDjO9Uarhb1D0mpvnMgo41TJAArsG7cWsPl5AN5OB3L/SJgG53N9Cfdl4cWYH0R41KXJ5DKrjh2HDq32I7SUx0IwBq8kwcwsEktn29gNMJWC213v+T9vMPdaEIO6TXNlT0YeKFW+BRorI15pSKazitcZ0kv6+v09p1ovdih66xU6ihnk+7RYwRj28cIiElPXDJvzR4FeFqvleKejfFa/XCRxdQPYDQdd/XeBlTrQhBRNxoZg/u8wpkN0GSrpHcy0hMp17MxJHyl8aJaHMqbGXcYEiPcHnktmS0CNXOhRbo5kNlnXQSiN2Rr9YmnvAgjm22Q4sDmuZY9M9ujgaWst0/HfJtpKuPgQ9LwL5J2LYvLJYs01bP+wmggLfjkUCep41MCGSnFMmuOZwKHGaiIJr3EEUBtkP5pQ5OAhf/DtgB4wP/QT9vBtoP8D7VNdFCrGSnnAAAAAElFTkSuQmCC');
			}

			&.facebook:before {
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAptJREFUSEvFl09yEkEUxr8Xk7iUG4gnEBYKrpyUzDrxBOIJhIVQrsSVNbgATyCewGQNKceVGbIInkC8AW4D9LN6oMdmmD/dYJWzm5rX79df99ev3xAMn2decHZAfMpAkRhFEBXXQycMnoHIJ6aLYevxxCQlZQU5vZvC4eL2FRgNAgomCcE8FaDOZbvyOSs+Fex2x3UG94yB25QJQC/TViAR7HrBAIQXRgpzg0L4IB62BXa9YALCw9x8WgCDfxGTz8A0Nq5BhHvM3By1q3392wbYVikzfgPciSdVALcb+ACeynfBeH7ZrpyrbxFY7inAn2yUxpPFx+pgBmaLpSj7b56EqxKCQ/fOb3/aGInBF6NW9SxrojpYxjHYH7WqJxG45gUdIrzdV60UcDSfa/5gua8lPS/Twcno9SM/VFzzgpk0gQ1YJVBjTFdNrRStKhK+2EBl7LBV2TBm7cO1Qyy+muSRY8nWySrxPmBpSoobwGTG+ypmxjuqda+mBLqfC2T8AGGmKXb0MW53XAJCM0UPM0pJ3gnBbjfgXKg8Cms3msRqBSQt97f/CDaszbaKs1yuljqqp1nL+G/B3CSLqrXqNNaPKn1/93NcYoieeo91KTFNVKa1G29sTLPPcZI32qhdKaxKpumR0ma3ewHhj8NWVbZSwC5X4q7g+VI8kFdjVG9tVe8GXqmVYvVGQFYe4722Bcv2aHF0t+Q3y6FBN1sfiy7EBiwNRUSO3nFuNXs176pBRNGxSHO7KTgJuqVYO5N1Zu5nNQdG4PBioXpSb53a0DvvvxcP71CfQKdJqvPAsiwujo/7ak/jOTJ/YcIzLjsLIerxBj8JDCHOiXgwX3JfdZNpW5ULVgNX/1GLEoRwCCgO25W6nlR+T1OXBP8DxA6Q07oFOlQAAAAASUVORK5CYII=');
			}

			&.linkedin:before {
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAr5JREFUSEu1V02W0kAQ/irge7oSTyA3kDnBMCeQwMqnwWEZXAyeQLgBLoQlDHk+V8BwAvEE5gbDEZiVLsaUrzs/dJIOCQxk29X19df9fVUVQsFv5CwbYO+SiGoMVAioia0MuARsmdnlknH76b3pFklJ+4Imk2Xlbxk3zNwjQqVIQjA2bKDf/dC83RefCewz5ElhwCQKYwOijm2Za90BtMAjZzEl4GMhhnlBzB273Zomw1LA49niDoS3efkOXP9qW82euicGvGPKDwCGgLEGvDpAXw4ESocnmEfA4k0JvJRKZf7cbbeG4e6xM++fAtwz6CJUvQQW6v1T4vudkOhKFcXYWdYB/vlk1sDatppXIo8ETjI6F2Pf92R2LfMuBN4C9DJkxIytQdT3mF0iEmz7J2ArU4iC07WaF6S+7amS5+V5/kivaOwshIhu1GBm/CLiyPjMVCfCpR/Dg+hmQFXhdxkPnoKMTRDTAPhavcXYYZg7NJot1rukUcqBbbWi61U1YFvNyAlSdOxVdQVCCrbsuQR6rfHWQDAWRf1NfJELAYvknY65zbra8Wx+DaJJcl3e0NhZsO5ERRhHPv+xrHoeKrrOpMt/EmCVVahYlYjuKUPgo686qAExKwLx4qPVEGP1JHH5wMmnKgAMluJK2UlYpugbHwMsqhd9+76sGR7/PkbVxzKWBURsHjnzTdxvZ2TMWNntZsOv1Sm/nRE4EJ/Sj1XWZwIO2EZt0X8rteeeA5gfUDZq9jtT1vPY6BNduZwQERR82cuqIFQDASanxnqswQRzttJia/6AEbdZetjT2iuv0eWsayZN7XibVdwPhxdDo9HQzdaZA73wN/3jYbplFoRnrPCMeuGbJnft/YXxPS6nT9GbE60z4wACkGiY9QcR7soFDgPlf1TJawiRiYlESbAFscsw3BePWO/rz+pR/wP4tsj9PQYsmwAAAABJRU5ErkJggg==');
			}

			&.youtube:before {
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAsNJREFUSEu1V8FuElEUPXfGtO7ElTGpDM+EAnEh/YLSLxAT98IX1MSVboqJm+507aI0cW8bP0D8AlmYgCbyhrbRuKMbtVDmmjcwFB5vhqGdzoYwc98995533r33EWI+3+RRmcGbABcBpABSvwC4CaAHUNOCvb8u1tT/hQ9FWUgpU31Y2wx+BlBqobdRIC6Bajnh7EfZhwKPMvT24gPqMOwy7GpB3GuYAjACt2S3TsDTeBlGWzGoWhDpum41B9yW3QMAj5IADXwQUM8JpzrtcwY4yUzniNcynwCPVfshyUx1XxbsjUD1PrBS7xlIXl5IccPlRl5ktpS1D9yS3RoBO9ryQ4b1Rr0LU2YYXEselyxwiuHVAHo4bReIzQdud9weiG4FBgz+XBCZUtw8wuwUk/+YmkTkXNhwMy8yG2TaWwI9zom0UveVHxObjBVBbdlVdG7P0mFtLUtvWISmxBTd1JJug0Cb0wtX4d0WQvRMzjqdn04fg9f2iv0iu7Z2sogStd8E75Nm95ba0m3qAsgLJ7SUtuSvDKEvGfhjAbvWeXo3m6WzKKHpwEpDimrWF8UBnlrzA6DneZE+NIGbMk4EmBlfAbws3Hc+Lgl8Oar99se0sy7S74nIW5rq5cX1+87A+vvk3HHePSDqX0ZcDLy69uPUkkcVAu/p1Yu+y5Oih+EXU1lblE2c76Y6oY7rqFZ3XNdU1uI4jrIJaT6HeeGUx01ino7x7FS3YM2MLgw61Qc6dWRuAJNjOQSnCFxkcAWgjKkqTgrFfNZXzXd+/XTzuQA2l7bk0JlPmVaLBXHXVU610cdEeTLYjNnGM1eTr2PuMk2aIeNtQpn79NplU4uN6ELHJWBY01tmXOKVkIDVSrCn+rrIK4x/xv3K46krzMzsFBGAP6stGiQWAgcAqg8Dg9L4fI4vbMFXagDk3sTwIGyA0AP9D/H+bx+Uxaw3AAAAAElFTkSuQmCC');
			}

			@include media(">=desktop") {
				color: #fff;
				padding-left: 1rem;
				margin: 0 0 0 -1rem;

				&.youtube:hover {
					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAA9UlEQVRYhc2YaxLCIAyEoeM5PZGH9QY4OoOjNO8slPwVNp8L2Vrr83EvjmqOtdUjfDOu8wBQe1QoDSQCIOmwQMcCCJMmBzIDQtSmQGZCsD1GkBUQZK+D+2BRNQrk0urj63VDywWP3ntt1XLElY6GfeL4XnE3TiXdkagbUrFOZS5rQ7qJmBoIEHJ8UzDoHAm7s12goSo8aSiQ9KgjjgaSNxlHIgBixLufCxOqakeDhBG1LEcjCYxuhsE7SAW8u0Tr82W2CbRfkBmPfa2+PUdHVsL89aKOZgXMqQd3R2bCkNq7/FRUc6RvzI5p+m8JSsgKZXe0lPICk3IoLpcAYhkAAAAASUVORK5CYII=');
					}
				}

				&.youtube:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAkNJREFUSEu9Vz1uE0EU/r4TBFeUgROATwA+QYxEn/gEREoFTRyJho7UFDgSPY44APEJcJEWkYID4AaJFLzoW2as2dmZ/fOSJ61Wlt+bb973fpdoKWY2BfAMwFMAD9xb1msAv9z7gqR+NwrrNMxMAK8AHDuwxgMB3ACYk7yoU84COw8/dgCMcXSBGcmr1AWSwGa2AHDYxr0WOgLXeSWpAJvZEsBBiwO7qCxIzkKDEvDAnsYXK3m+BXYx/dzFjR66Y5/1BbDL3h87JFLbO1yRnEjZA88BnEbWlwDeF0qZzMyhmdlz54TOfRLpFZR7YDWAvUBhRVLGO4ljUg1lPzhoTXLMTGxfkFR27yxmlmLzsYBFp7pTKJOu9NbQrlYbJ+1MwOos6sGhjEiK/oqYmWh7C+A1yZ9NlLh4f430zgWsGJQSgGRdK30EQBXwG8A7PST/NCRaDLwSsMVGLYG92XcAJyRVBSmGlKT/BfgawBuSX7oC96Va00e1/4nk3z5Ud02uhwBeAvhA8rZncp3dRzkdAdBcD6UoJ60y3yp/JGZok3eZGKf6xMi3TMWr0tb6AIU2meFzSXLqgVN06DLaHOLVZRMvdK5JhGXpl0Gdq7oPpeiK4TyOvd7V4ZT9dviEwKlCHxJ8o5WYpBz8N4+9mFmK8qHAS4MntewNuWH6S1c2zdx6O5TnoneaGrF1U0gx1xCPR2Zb6lcAjnxMY6PaTxgpu7jrEybenXIXKHa1pkWiEThIPNWjWFCn0xOKal3ZuswtEPEt7wCyiwcQTA/OGQAAAABJRU5ErkJggg==');
				}

				&.linkedin:hover {
					color: blue;

					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAB2klEQVR4nO1bWw6CMBAE4zk9kYf1BpgYayr0sc92t+38qBBhZhy3Syn76/nYlHEwDr9rk7srHZcjunQccUMkDZASDTmHmBESBrQQnjsn2wiOAT2E5ziQjbgxT2wFZD4UA6yJDzgo3DAGkE7QASiOUAM8CI8B5gsxwJv4ABDvmgFexQdU+VNHgQD1VlUARRNKfUDpi3vivcu05BIAFQ/ZbgFZPSkDTF+9MZDUha0BHv7zKJwN8F71a7jowyZgOINiAyTEuWuVJS+GXKaDOh8wQof4Kei3aMOU4LbC7jGzAZ/U343HP9V4ifKlFMFaN5gjWPoeZv4/3sc2w+JfANNus1tzawZQBLFMsGQAR0jz+wLS6HaVOXsfcGjdHeaAMoqE/ao3RlqgJEClX7FkQI+GbJ+9BphJQLd2fCXAAIeuWAaMONePwcwJ+JsTnBbLgO/rbHXgp3clIHo/Swr+dK4EnD6PnoKLvpWAxLZRU5DUlUvAaCZk9VDmBKnX7iYXYJRqwCgpKOqoFUHvJlT5Q0YBryaAeEOHQW8mgPli+gAvJqB4YkcBywujST8QtRO0lgYyH869QQtp6Prc4JlESyNMPTka0MII088OB4guYtKuN9rrA6Arw/oU1W3b3v+KQXtQeAxJAAAAAElFTkSuQmCC');
					}
				}

				&.linkedin:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAj1JREFUSEu1V4tRG0EMfa+CQAWBCiAVQCqIUwG4gpAKgjugg7gDcAWBCqADKAEqUOZdVh5Z7F7W2KeZG49vtfv0edLqiE4xsxmAMwCnAA7Kr3a/lOcewBPJVc+RHFMyMwH8AHBVwHrOlCFLkosx5SZw8fD3FoAZRwbMSSoS76QKbGZLABc97nXoXNe8fwdsZncAvnUcuI2KQj+PGzaAg6dvAG4AKEznAH5tg9LQXZC89rU1cMnpbVn4SVLAg5iZNuwD/KvnfAAu7H0ORForlHV5/WcPXr+QPNY5Dpw9mspjYYrpSwd+BfApeKT/Muap5Hidmz14vSI5Y8rtHs7tOuJQwCKRulOUh8Jof6ccq11KYkc6KvUufdW+moZE7fUyRTGePxewSsYP9cUN6kdWk4yVIIOOlLPsZyGsUvW5EoOFgLV4khZ7gQ9Iig9VMTN5rbab5UHAVrUoFHvLY99nZgq5jJATG9I4f3fg5JWuxS8RuZHKAfjDoRaAmeVSzM2nxqHVTuQqwDlVPcADuWrl1EWuHYC/C1ijzONHWL0D8KG3TBV+rLcpPf7XMovVud6mBB44ELtQ9Hoq4MFbOZvbn9+5UwBrqjklOfTzPPp4yH1Wds6pM+mR5KlR/TqK+kJsoz6Hb5RZbdirlVerHfe+Hy7/qNwab1vNvRfI9RTeWW22HhvoFSJ5n6/MXnB9ylx5TvOm0U+YUmpioUaffHW2DBDgTesLwjf9F9gVy8UuI0SySCgRSYTScz92P0dL/wKpp2HQCuJu6AAAAABJRU5ErkJggg==');
				}

				&.facebook:hover {
					color: blue;

					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA/CAYAAABXXxDfAAABkElEQVRoge2biw3DIAxEIeqcnajDdoNUjeqKfAADdjDYNwC55zOfEMW/X0/HrLVheM9p7cEwZgtsaizyQlDCU0LnxicpBAU8N3TqmU1FaIHvAR3zUFWEpfGhUlTlpxR+FQgOKvZVAi8VOlRROFj4EcBDofxi4EcDB2V951b7nuAlK3jM55oaJwXfC5z1SBuqdqvjkGcCj4YYg787de60L3mu4GcDB524JLX97TrCz5o6aMenOvlwq5N8mKG+INk6rmfy2JZnCwXgRz3CNkn6nOcKZRvXtjqtWrTO9y+3+uTVyuC1iuNbHYjipYX1FDhD8tW7lc15rTJ4rTJ4rbJDDpNy+y/mAMP6xrl0uD6WIm9zXqsAXlvrd7+37y6D/0lL6/85LflAs6e/47PkD5o1/RNXLPnZCnDJk2r7WQoQ5bA5n9Do6Sf9Y5IftQBZ39i2H60AKL8lNzkwoOTv+UUh1Sx4Urug2Fftai+tAFV+Wi4wJUyDbv/VHQ3cWQQxf1SCQkMchRD9L20oqkLwrS3OuQ8J5DR2kdbNzgAAAABJRU5ErkJggg==');
					}
				}

				&.facebook:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAh5JREFUSEvFl4ExBEEQRf+PwGWACBABFwEiQARcBIgAESACROBEgAiQgYug1Z+aObNzu3s9t6p0lSp7N9Nvu/tPTx/hNDM7ALAPYCP70+43AN8ApgCeSOp5qbFvhZmNAJwCOAOg/z32CeCC5H3f4k6wmR0DuKoAlhxFftKVgVawmd0BOPKE51gjuPw1bAFsZnrTLYfDfMlXrLHSnJtKtAZgQvI6/6IBXiHSWaxnw2kCmJkEtxufD0k+pu/m4FjT28pIG87KvQVYyt8hGbISwFG9H5VC0tHREeu0Aqx1U5LjHHwB4HxotDGAXB8qwXbhd0xymiJWGiSCGgsOsnrqnHuyFjLF2JEeaoghVWQpzD0Azx4/2ivwSmd2CBjAocC55D0vHNYMBF8KLHmvO4jv8TJIYKV2bmYmEZXnWZ+1aSeAzQHVkoaYPHt6fL/8K9jbm6siNrM+lYdUe8X1l+CJwN6ulSaNJK7Q+rIGIiHp/k6WJpU2OewIrA2vHrHkawYcpxnJUWqZ3iM1Zw8A35A8S2CNOVVX4gDwpq7G/D6uinpFcIg2dL5CHO5arwDWeLRNUjfhL1gPNVNIJVgj0l4+cbYNe0pFfixaBV8BXoAuRJylXWJTw+8cDpxgXSzHbbN130CvBiC4frYsmAN8qf2ppqWD3p8wse7qucpAY8DvAGt81WAhYDljN9hLwVn6NVOpy+lFNkjqZfKWOeqKri1jP2K2EQaRfpb5AAAAAElFTkSuQmCC');
				}

				&.twitter:hover {
					color: blue;

					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAEfUlEQVR4nO2bv09TURTHT6G0FfrDohADrQsMpnWBQcokg1ITjAs46GBE/wBccFMTR2DQzQkG3AB31MXEiJiIiwUHWBSMFiPQFmjLL3NuevFR3mvf/fFeXxs+SZPHo733nu8959xf79nWR+6AwRwIFm8zsnl2g8oVNVqrLOliyBZApuGFypcmhCwBjDZcqz5hIUQFMNtwrfq5heAVoNSG58MtRJVAZVaEuW2sAljZeApTG/WGQDkYrkR3SOjxgHIzXknRtvPkgIqimADl3PuUgjYUEqASjKdo2qIlQCUZT1G16SQHqNyrxN6nHLMtfx5QUuM//kjDq/ltmPmehpXkHrnXEXBAuNEBd9vcEPCpT1sS6X3wunQ784FyfpC/IaJbgOWNXc0GsYIGDE6vwZul7YK/HOj0kg9lKrYJzz4kyL2+i3UstR4KoLSAqfcHp/9C0FcDQ1E/u8UK0PhbE3FYWN0t+t3nMwmYj++QayrWpWYHq/Gg9AKuLsTen13Okg8iIgL2vB7jKUovcTurYORaPblmDINDuEaB5cT/Bk/GNuHh9BppACsY88XcXosmTzU86fLB1PwW9Iz/OtImFqgHCCU/FCEWz8BwtB5CjQ7dvxudS3HX+TO5R7wHGer2M9Wbg4SBtHkAuvH1l3ESp3q9YXY5I1wvGs+RAw7hEiDg1U4dKEDP+G+SoYsJkczwOx7G/4sbZ4SMR+w87o/D35UWF7xdSqv+fyXnnh7nOtwM10FH0AlXW04JNTSf0Fk7dLcKl3nAPZDfa3drCkDBHsY4x4/HaYNQQw1Egi5yHW5gjllD4BYADelvc8PYF32JDMVQDp1WgUuA0c8pSGb3oTdcS/7WK4IV4RIg1GiH2xN/SMIrFZ3nXVJq5hoFQhaI32ZPtZRyuATAKSeOAqUkEnSWTgDkgWJVZjYXGuzSVqLcAuDUE2dhpSDaWiutViEZ6Szs6bsNSGXYF0O89IbkCSC8FkAR3t8/RzYlmiQlpkJg7pHl/ohwSa8Xt2FsLkmucW5gNLJzj7AAOL2Nre6aEgLo+hzL3oIIhwC64+PLPqmNUgNXf4+6TksvV8p+AOaBAYOHxZGon2vLqxjSSkQBcH1uRCLERZeEpa8qdFtc6nkAJsaF1R2YXtyCbwwbnmpg3A/nNj4NQN6WmBLaWxY3niD9QUl6WEFPdngxw3iECmDjDYP5eJa4O250oMuL7PMB3euP+g2LeQV8ByO4l489/GlF/s4O9joOdUZkey2UZ4NMXTf5dZOcB8gQAg3vb3dLn+QUgXgA9+EoBY/JxuZSEItndYuBbh4JOCAScEFfuNbUHs8hfjqsBYZIInNA8kI+eNTtdVaZ3dNqqJ4Og0gypOBuMSiGQgty5NlBNd8z9AWFEnPMtpNnhDTuV6IXqNpUyAMqSQRNW4qFQCWIUNCGkxyg4zvl7AVSHpfXVZAF0dVmlsUQLdDqT5IydRZPDrCyN5jy0hRXRSbA1SaRHSGrhIRQZ8jYElM2wCwxLPfqLMVor7D8y9OU/IbyCmJsrgGAf3lbagCe+gJoAAAAAElFTkSuQmCC');
					}
				}

				&.twitter:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAmJJREFUSEu9V4tRGzEQfa8C6CC4AkwFQAVABcEVBCoAKgAqwFSAqSC4AuwKAhWQVLCZp1kdh6yTTgzDztx4bGv37fdpjxgpZnYA4AjAFMC2f0p7BeCvf96T1PeqsHTCzATwC8CZg1UNAngBcEPytnR4ENjMjgHcNQCmOHLgnOQi50AW2MxuPNIxEdbOnOWi3wA2szmAnzVrjf/PSc76Oh+AvzjS1DelXZkM0gF7TR8aI2k9fkjyqQP27v3zyUb6B0DlUTPtADgFsOUevQL40fPuheSkD3wJ4CJxf+nGSvVeAzggqTkO4kFo/NTNsqvZ78uM5Dyk2sykGL2Mh65IXlaabUJSkW6Il05kkwYUoqYz0u+MbgB2x8RaSmc/bUuS+j0rHrnsCjyVADw0s08kD/saHoVqKGPyvAScK180dy5gddl+xis1jYZfkTZLwa5sPQpYpL6bS7Vzbtc4LegV4KWAbcDgiuReC1g86/V9K+iuSsDS26C6MY6YmfpAF8yQrEuplpIIQOChu8dKoXzRREj1UHPFQ3tjL3cpjKTe0Fy1K1DNdRI5thS1mYkyn0dQbxgnzaQO1+SRpJaDrFQII9WZDFGmZliRig71qM7hVsmJs59uNq1KNVmTnEZgkfp1oiGSv60AKlvaydTFY+X9kvCmUGR9Lo6G9LtIpr89ClCPatoiHb9/9yLQTUi6+uRS3hJR6WxIcTzwXcted8UOAnu9vzLyD5EWgR08d/m3pF2rk67V7CtN8RXGHdCoiKtzHZ9zRHuYXmGK93gVOFp2OhRzKRMiCo2SSCY+IpjF0A6WevgfbQYFr/WzSQIAAAAASUVORK5CYII=');
				}
			}
		}
	}

	.footer-section {
		padding-top: 2rem;
		padding-bottom: 1.5rem;

		&--icons {
			padding: 2rem 0 2rem 0;
		}
	}

	@include media(">=desktop") {
		.footer-section {
			padding-top: 7rem;
			padding-bottom: 1.5rem;

			&--icons {
				padding: 4rem 0 4rem 0;
			}
		}

		.footer-logo {
			padding-bottom: 2rem;
		}
	}
}

	.footer-link {
		text-decoration: none;
		color: #fff;

		&:hover {
		}
	}

	.footer-link-legal {
		text-decoration: none;
		color: $light-blue;
		font-weight: 100;

		&:hover {
		}
	}
