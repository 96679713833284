﻿.category-card-container {
	min-height: 62rem;

	.category-card-content {
		background-color: $dark-blue;
		min-height: 62rem;


		img {
			width: 100%;
			height: auto;
		}
	}

	.category-card-summary {
		width: 75%;
		margin: auto;

		ul {
			list-style: none;
			padding: 0;
			font-size: 1.6rem;
			font-weight: bold;
		}

		a {
			color: #fff;
			text-decoration: none;
		}

		a:hover {
			color: $orange;
		}
	}
}

.category-card-headline {
	text-align: center;
	margin-bottom: 4rem;
	padding-top: 2rem;

	h3 {
		margin-bottom: 0;
		font-size: 4rem;
	}
}

.category-card-section {
	margin: auto;

	@include media(">=tablet") {
		margin: 2rem auto;
		min-height: 65rem;
		justify-content: center;
		flex-wrap: wrap;
	}
}


@include media(">=tablet") {

	.category-card-container {
		min-height: 66rem;

		.category-card-content {
			background-color: $dark-blue;
			min-height: 75rem;
			position: relative;
			min-width: 34rem;

			img {
				width: 100%;
				height: auto;
			}

			&--short {
				background-color: $dark-blue;
				min-height: 54rem;
				position: relative;
				min-width: 34rem;

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.category-card-summary {

			ul {
				list-style: none;
				padding: 0;
				font-size: 1.6rem;
				font-weight: bold;
			}

			h5 {
				margin-bottom: 1rem;
			}

			h4 {
				margin-top: 2rem;
			}
		}
	}

	.category-card-headline {
		text-align: center;

		h3 {
			margin-bottom: 0;
		}
	}
}
