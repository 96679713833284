﻿#my-menu {
	display: block;
	color: $white;
	@include media(">=desktop") {
		display: none;
	}
}

.mm-spn.mm-spn--light {
	background-color: #000729;
}

.mm-ocd--open .mm-ocd__content {
	opacity: .9;
	width: 100%;
}

.mm-spn li {
	font-size: 2.5rem;
	margin-top: 2rem;
	margin-left: 2rem;
}

.mm-spn li:before {
	display: none;
}

.mm-spn li:after {
	display: none;
}

.mm-spn.mm-spn--navbar:after {
	font-size: 1.5rem;
	opacity: 1;
}

.mm-spn.mm-spn--navbar ul:before {
	opacity: 1;
	border-top: 2px solid #fff;
}

.mm-ocd {
	top: 13.4rem;
}

.mm-ocd__backdrop {
	display: none;
}

