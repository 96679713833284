﻿@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	.product-card-content {
		height: 100%;

		img {
			flex-shrink: 0;
		}
	}

	.search-icon {
		@include media(">=desktop") {
			padding-right: 3rem !important;
		}
	}

	.search-icon-mobile {
		padding: 23px 50px 60px 32px !important;
		
	}
}