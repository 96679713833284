﻿$breakpoints: (
	'phone': 480px,
	'tablet': 769px,
	'desktop': 1024px,
	'widescreen': 1216px,
	'fullhd': 1408px
);

$orange: #F08C5E;
$darker-blue: #000729; 
$dark-blue: #121A42;
$light-blue: #59C9FC;
$white: #fff;

html {
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	box-sizing: border-box;
	width: 100%;
	overflow-x: hidden;
}

// Box sizing
* {
	&,
	&::before,
	&::after {
		box-sizing: inherit;
	}
}

// Bulma horiz-overflow bugfix
.columns {
	max-width: 100%;
}

button {
	outline: none;
	cursor: pointer;
}

.show {
	display: block;
}

.hide {
	display: none;
}

.noscroll {
	overflow: hidden;
}
