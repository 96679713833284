﻿.splitscreensection {
	width: 1440px;
}

.imgContainer {
	display: inline-flex;
	width: 100%;
	min-height: 486px;
}

.imgContent {
	width: 50%
}

.imgTextContent {
	width: 50%;
	background-color: #000729;
}

.screenSummary {
	position: relative;
	min-width: 80%;
	margin: auto;
	color: #fff;
}

.screenSummary > h4 {
	color: $light-blue;
}

.site-footer {
	width: 100%;
	background-color: #000729;
	min-height: 400px;
}
