﻿.primary-navigation {
	background-color: $dark-blue;
	position: relative;
	color: #fff;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@include media(">=desktop") {
		padding: 0 2rem;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;

		@include media(">=desktop") {
			position: static;
		}
	}

	.contact-mobile {
		width: 100%;
		min-height: 5rem;
		text-align: center;
		font-weight: bold;
		font-size: 2rem;
		background-color: $darker-blue;
		padding: 1rem 0 0;

		&:before {
			display: inline-block;
			content: " ";
			width: 2.2rem;
			height: 2.4rem;
			margin-right: 1rem;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			vertical-align: middle;
		}

		a {
			color: $orange;
			text-decoration: none;
		}

		@include media(">=tablet") {
			display: none;
		}
	}

	.logo {
		flex: 1 1 50%;
		padding-left: 1rem;
		display: none;

		@include media(">=desktop") {
			flex: 0 0 20%;
			order: 1;
			padding: 0;
			display: block;
		}

		img {
			width: 100%;

			@include media(">=tablet") {
				max-width: 20rem;
			}

			@include media(">=desktop") {
				max-width: 25rem;
			}

			@include media(">=widescreen") {
				max-width: 30rem;
			}
		}
	}

	.logo-mobile {
		flex: 1 1 50%;
		padding: 1.5rem 1rem 1.5rem 2rem;

		@include media(">=desktop") {
			display: none;
		}
	}

	.search {
		flex: 0 0 25%;

		@include media(">=desktop") {
			flex: 0 0 3%;
			order: 3;
		}
	}

	.menu {
		display: flex;
		flex: 0 0 100%;
		flex-direction: column;
		margin: 0 auto;
		padding: 0;
		overflow: hidden;
		transition: all 0.2s ease-out;
		background: $dark-blue;
		height: 0;

		@include media(">=desktop") {
			flex: 0 1 95%;
			height: auto;
			flex-direction: row;
			background: none;
			order: 2;
			align-items: center;
			margin-top: 2rem;
		}

		@include media(">=fullhd") {
			flex: 0 1 75%;
			margin-top: 0;
		}
	}

	.search-menu {
		display: flex;
		flex: 0 0 100%;
		flex-direction: column;
		margin: 0 auto;
		padding: 0;
		overflow: hidden;
		transition: all 0.2s ease-out;
		background: $dark-blue;
		height: 0;
		order: 10; //last
		@include media(">=desktop") {
			position: absolute;
			top: 104%;
			z-index: 9999;
			right: 0;
			width: 43%;
		}

		.search-form {
			padding: 0;
		}

		.search-field-wrapper {
			position: relative;
			padding: 1rem;

			& .search-submit {
				position: absolute;
				right: 1rem;
				top: 0;
				bottom: 0;
				margin: auto;
				border: none;
				background: none;
			}
		}

		input {
			background-color: $white;
			color: $grey;
			font-weight: bold;
			text-transform: uppercase;

			&::placeholder {
				color: $grey;
			}
		}
	}

	.nav-item {
		display: flex;
		margin: 1rem auto;
		padding: 2.5rem 0;
		text-align: center;
		font-size: 2.5rem;
		list-style-type: none;
		text-transform: uppercase;

		@include media(">=desktop") {
			font-size: 1.3rem;
			margin: 0 auto;
		}

		&__aux {
			position: relative;
		}

		& > a {
			padding: 2rem .5rem;
			text-decoration: none;
			color: #fff;
			position: relative;

			&:hover,
			&.active {
				color: $orange;
			}

			&.active {
				font-weight: bold;
			}
		}

		&:hover .dropdown {
			display: flex;
		}

		.dropdown {
			display: none;
			position: absolute;
			top: 96%;
			right: 0;
			left: 0;
			z-index: 9999;
			padding: 0 4rem;
			justify-content: center;
			background: $darker-blue;

			h3, h3>.group-link {
				color: $light-blue;
				font-size: 2rem;
				margin-top: 0;
			}

			.group {
				flex: 0 1 50%;
				display: flex;
				flex-direction: column;
				padding: 2rem;
				text-align: left;

				p {
					margin: 0 0 1rem;
				}

				&-link {
					color: $white;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		&--secondary {
			font-size: 1.3rem;
		}

		&--secondary > a {
			position: relative;
		}

		.phone {
			padding-left: 2rem;
			color: $orange;
			margin: 2rem 0 2rem -3.2rem;
			display: none;

			&:before {
				display: inline-block;
				content: " ";
				width: 2.2rem;
				height: 2.4rem;
				margin-right: 1rem;
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				vertical-align: middle;
			}

			@include media(">=desktop") {
				color: $orange;
				padding-left: 1rem;
				margin: 0 0 0 -1rem;
				display: block;

				&:hover {
					color: $light-blue;
				}

				&:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
				}
			}
		}
	}

	.search-icon {
		padding: 0 28px 28px;
		position: relative;
		cursor: pointer;
		flex-direction: row-reverse;
		display: none;

		@include media(">=desktop") {
			flex: 0 0 1%;
			order: 3;
			margin: 0 auto;
			display: flex;
		}

		.search.icon {
			position: absolute;
			margin-left: 10px;
			width: 30px;
			height: 30px;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAnJJREFUSEu1lUF6EkEQhd+baVBXISeQnMCwMODKcAJxB24kJxBPYHKCkBMYN4IryQlkZ4iLcAPhBmRFEmam/GqYmTQwMEnUXs03X3X9Xa+rXhNrVv2nFIXBG0JqJHYBFDRUBH06MhTirPsy11+3P/7P5YDapRSe3vjHJJpZmxUGV442gRYA7y5kV8T/kZwWuIImoQxBjiBSpGglrIF4Hh8gELa+VdyTtAMlgCj5ZRwkkKPrvGn3SpykbWwMvBoE7Rik8d1y7nA5NgSo3nR8TV4Q4Mqhu/91j8MsiVTOZ7deD+DrKPZtp2x69r45YOCdEniv36Rbuk/yOEl4Z7den+ALEYyun7glu2pGp/8ddsiaMrMqWZBX+LFTcdtJFzXO/RYox/pjmne312meBblTQYadcq50BxjM+qqhCM66FVPLSrR2bi68JgWflw/K+sCbENh6rDwx0JZJHKnGs8HGwJO/0d+uKMn1PwALzbIIiO4A+NItm0x7WHcH4eAB30M1Anen+4qjsO0b57M2yA8AJp2y2X7sJSd5BONOxRSTLqr/mu0zoPoPhDjo7pnTh0LmE+3rLBUgctKp5FoJQD8aUatqFdO8u/PQWbCdwJYnlCi0CqsKQIbTvKneF2JJnOoEiZvaE62eAlcONvl81DXqAMlwpvnYwntQH8wOCX6K9dMHRcCe4zhD8TGG8Yric4uAvnKaOHzlrDUh3aptlisv2rLPZ124OoBD0wvE76sj6D3akBWAJgy74iZoCoOm2vAKRDAGpCdi2nG/q1WkQVIBdsLQ7z1PH/358swoTroMToNkArIk2gRRh/7nAAXOndVrk6b1BzqAZpo38iHYAAAAAElFTkSuQmCC');
			background-repeat: no-repeat;
		}
	}

	.search-icon-mobile {
		padding: 24px 28px 60px;
		position: relative;
		cursor: pointer;
		display: flex;
		flex-direction: row-reverse;
		border-right: 2px solid #fff;

		@include media(">=desktop") {
			flex: 0 0 1%;
			order: 3;
			padding-right: 0;
			margin: 0 auto;
			display: none;
		}

		.search.icon-mobile {
			position: absolute;
			margin-top: 2px;
			margin-left: 10px;
			width: 30px;
			height: 30px;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABHNCSVQICAgIfAhkiAAAAkxJREFUSEu1loFRFEEQRf+PQI1AjUAyUCJQIhAiECIQIpAMPCIQIxAiQCIQM9AI2npbPVezs7O3dx501dZd7c70n/7d87utDRYRzyW9lfRB0itJ73L5H0k/87m2fbvJT/3Ncwsj4lzSJ0mALtmDpHPbV0sLJ4ARcSDpqyR+W6sjIfLWriWd2IaBro0AIwLKvjVRcepL21A4soiAap6P1QfWHdkm6omtATOyHxXYPc7mNtae8qArSS/zPaCHvUgHwCwOwAqNV7aPl/LRgJLrG0lv8v3K9knrowBSIJ/z473tXv4W8fPgUPksFxMlh1ibc9GvisrX29A4h555pQ6wG9uHLSBJLwt2prIHHBHksFD7os4lEZLsUmVUF6W9l0XEqaQv6YRrAsZgAMLxcKdszwrBLifIir/LPRe2qZE1YOT/37aRr0exiCh+v9smbRPAW9tFK/cGrQBHfqEUGRrK+LEoxdemCNc5lDSqqP8NM5UHIcEmObzMrsDHUUXtAVj7HFU+lJK3cprJRd0VtBGSv/TR0T1MvpGjIrwTOdoFNPtokcmJkBQtrdWGIgJ00o6WgBu2iO6glcm6PaEw79MpEcP91qCdXnpmm1yOrAZs2wuRni6NDZkzRpG1miRCtwDbjo/SEGkRXvYSLe94UKOHlC5yTsHRN+fmnglob6ZhM1TUY8NS+sr3izwgM1GxsXjPecqcQFNvWGq3MfcwtQ1zTEQQdRd0sTtkjtq5FL8oFEXF3Z1MaXOgi4Dbctlb1wE9elLADr1nTw6YoCUlq39YwwfzUq+ApwAAAABJRU5ErkJggg==');
			background-repeat: no-repeat;
		}
	}

	.menu-icon {
		padding: 28px;
		position: relative;
		cursor: pointer;
		display: flex;
		flex-direction: row-reverse;
		border-left: 1px solid transparent;

		@include media(">=desktop") {
			display: none;
		}

		.nav-icon {
			background: #fff;
			display: block;
			height: 3px;
			width: 24px;
			position: relative;
			transition: 0.2s ease-out;

			&:before {
				background: #fff;
				content: "";
				display: block;
				height: 100%;
				width: 100%;
				position: absolute;
				top: 6px;
				transition: all 0.2s ease-out;
			}

			&:after {
				background: #fff;
				content: "";
				display: block;
				height: 100%;
				width: 100%;
				position: absolute;
				top: -6px;
				transition: all 0.2s ease-out;
			}
		}
	}

	.menu-btn,
	.search-btn {
		display: none;
		flex: 0 0 0%;
	}

	.menu-icon.active {
		background: $light-blue;
		border-left: 1px solid #fff;
	}

	.menu-icon.active .nav-icon {
		background: transparent;
	}

	.menu-icon.active .nav-icon:before {
		transform: rotate(-45deg);
		top: 0;
	}

	.menu-icon.active .nav-icon:after {
		transform: rotate(45deg);
		top: 0;
	}

	.search-btn:checked ~ .search-icon {
		//transform: rotate(-90deg);
	}

	.search-btn:checked ~ .search-menu {
		//show/hide transition
		flex: 1 1 100%;
		height: auto;
		padding: 1rem 0;

		@include media(">=desktop") {
			padding: 1rem 0;
		}
	}
}
