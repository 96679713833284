﻿html {
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	font-family: Montserrat;
	box-sizing: border-box;
}

body {
	font-family: Montserrat;
	background: radial-gradient(circle at right, #49BCEF 13%, #000729 60%);
}

h1 {
	font-family: Montserrat;
	font-size: 6.5rem;
	font-weight: bold;
	margin: .2rem 0
}

h2 {
	font-family: Montserrat;
	font-size: 4.5rem;
	font-weight: bold;
	margin: 0
}

h3 {
	font-family: Montserrat;
	font-size: 3.5rem;
	font-weight: bold;
}

h4 {
	font-family: Montserrat;
	font-size: 2.5rem;
	font-weight: bold;
	line-height: 2.8rem;
}

h5 {
	font-family: Montserrat;
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1.9rem;
	text-transform: uppercase;
}

p {
	font-family: Montserrat;
	font-size: 1.6rem;
}

option {
	font-family: Montserrat;
}