﻿.button-section {
    width: 100%;
    font-size: 1.6rem;
    margin: 5rem 0;
    text-align: center
}

.contact-button {
    position: fixed;
    border: none;
    text-align: left;
    color: #fff;
    background-color: $orange;
    font-size: 18px;
    font-weight: bold;
    z-index: 9998;
    text-decoration: none;
    padding: 1.4rem 5rem;
    cursor: pointer;
    bottom: 0;

    &:before {
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAPNJREFUOE/tlEFOQjEQhuefoAs3VFbkrVx4A7YmlncCuQHeQE+iN4AruHP1qDfwFEBCAiUhLhT6m8bYNISQ95YkdNMmM9/MdDr9YbrlEMoXCIzUWBR6Bjz7eTXGdVF6foUb753/Z01hH3bfsr241F74CW9+4T6TzVijV7paTiugU5QMgTZPyh1vRWUD6F3Y8l1bssntqnAJJvlRo+LkAuA+wfHQBI7VnuEGHTs37DQnrMETJ9ejH6NT9Mck2qtZNdgPfnRITNdaAKMIkXz0c+fyAAk+JEPRMWaO+3I6GeagyWXoTwDlFSLtOnenyJpBnqIA/gK+KN4X35zy2AAAAABJRU5ErkJggg==');
        width: 30px;
        height: 10px;
        float: left;
    }

    &:hover {
        background-color: $dark-blue;
        border: 1px solid $orange;


        &:before {
            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAbCAYAAACa9mScAAAABHNCSVQICAgIfAhkiAAAAORJREFUOE/tleENAUEQhb/XARWgAkrQASqgA3RABZRABXQgOlCCEnTwZC53OC5ixR/JTXK5vezsy87L3Dey3QN2QJv0OAMj2Y7FRtLiUcN2Ixe+AC1Jx4r9GTAIEQNNSZF8C9sTIJ5T/m5X5PSBQyFSukVCVWHBpBBZJhx8TA2RcSYiSd+I2L6XU4tkFtaevHZS7cnfeAL8BEolfiawJTjcfQsl22tgCiyfQf7xD5hDfA6sqsBV6ligIylGRyls72MkAFtJQf7n/cDjPsrZRF3xkeBFkToEjhmgbccQCpNS4yJpfQU02+A3uYImUwAAAABJRU5ErkJggg==');
            width: 30px;
            height: 10px;
            float: left;
        }
    }

    @include media(">=tablet") {
        right: 10rem;
        transform-origin: bottom right;
        padding: 1.5rem 2rem;
    }
}

.product-button-container {
    margin: 2rem 2rem 2rem 0;

    @include media(">=tablet") {
        margin: 2rem;
    }
}

@media screen and (max-width: 1291px) {
    .is-half .header-content--about-right .product-button-container {
        margin: 2rem 0 2rem 0;

        .button-productdetail.products-anchor {
            margin-right: 0px;
        }
    }
}

@media screen and (max-width: 1090px) {
    .is-half .header-content--about-right .product-button-container {
        margin: 2rem 0 2rem 0;

        .button-productdetail.products-anchor {
            padding: 1.2rem 0rem;
            margin-right: 10px;
        }

        .button-productdetail.contact-cta {
            padding: 1.2rem 0 1.2rem 0;
        }

        .button-productdetail {
            &:after {
                padding-left: 1rem;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .is-half .header-content--about-right .product-button-container {
        margin: 2rem 0 2rem 0;

        .button-productdetail.products-anchor {
            padding: 1.2rem 0rem;
            margin-right: 10px;
        }

        .button-productdetail.contact-cta {
            padding: 1.2rem 0 1.2rem 0;
        }

        .button-productdetail {
            &:after {
                padding-left: 0;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .is-half .header-content--about-right .product-button-container {
        float: left;
        margin: 0 0 0 0;

        .button-productdetail.products-anchor {
            padding: 1.2rem 0rem;
            margin-right: 2em;
        }

        .button-productdetail.contact-cta {
            padding: 1.2rem 1.5rem 1.2rem 0;
        }

        .button-productdetail {
            &:after {
                padding-left: 1em;
            }
        }
    }
}

/* iphone 6+, 6s+, 7+, 8+ */
/* iphone X , XS, 11 Pro, 12 Mini */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    body {
        .quote-button {
            text-align: center;
            width: 100%;
            margin-bottom: 48px;
        }

        .contact-button {
            width: 100%;
            text-align: center;

            &:before {
                position: absolute;
                margin-left: -27px;
            }
        }
    }
}

/* iphone 6, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    body {
        .quote-button {
            text-align: center;
            width: 100%;
            margin-bottom: 48px;
        }

        .contact-button {
            width: 100%;
            text-align: center;

            &:before {
                position: absolute;
                margin-left: -27px;
            }
        }
    }
}

/* Samsung Galaxy s6/s7/s7 edge */
/* Samsung Galaxy s8/s8+ */
@media only screen and (min-device-width: 360px) and (max-device-height: 740px) and (-webkit-device-pixel-ratio: 4) {
    body {
        .quote-button {
            text-align: center;
            width: 100%;
            margin-bottom: 48px;
        }

        .contact-button {
            width: 100%;
            text-align: center;

            &:before {
                position: absolute;
                margin-left: -27px;
            }
        }
    }
}


.product-button-section {
    display: block;

    @include media(">=tablet") {
        display: flex;
    }
}

.quote-button {
    position: fixed;
    text-align: left;
    color: #fff;
    background-color: #000729;
    font-weight: bold;
    border: none;
    background-color: $orange;
    font-size: 18px;
    bottom: 0;
    right: 0;
    font-size: 1.6rem;
    z-index: 9999;
    text-decoration: none;
    padding: 1.5rem 3.25rem;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: -5px 0 .4em #888888;

    &:hover {
        background-color: $dark-blue;
        border: 1px solid $orange;
    }

    @include media(">=tablet") {
        transform: rotate(-90deg);
        transform-origin: bottom right;
        padding: 1.5rem 2rem;
        bottom: inherit;
        top: 33.1rem;
        box-shadow: none;
    }
}

.button {
    font-size: 1.6rem;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    border: 1px solid transparent;
    text-transform: uppercase;

    &:after {
        position: absolute;
        right: 2rem;
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAANCAYAAACkTj4ZAAAABHNCSVQICAgIfAhkiAAAAKRJREFUKFNjZCASvO+KMmBkYtn//98fR8GyZRfQtTESaQ5Y2fueuAWMDAz+2AwjySB8hpFsEC7DyDIIm2GMoEBkYWHmJyWsYGr//mWcwMDIoAAKM8b3PbEXGBkY9ckxCKbnPwPDRPK9BksODAwbBUsWJZBlEDxNQQ0BuYxkg7AZQrJBuAwhyaD3/QkKjH//nf+P5B3kCCLJa+97YxMEixcvwBbDAJTzWHqyOA0SAAAAAElFTkSuQmCC');
        width: 10px;
        height: 10px;
    }

    &:hover:after {
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAAq0lEQVQ4jcWUuwrCQBBFT3xgfFUi+Bf+tQiWgihYaWNh7RfYWFppIRxZ3IBgBBMjXrjNMnO4y84sahEP1Zu6UtO8vqLAAJn70FZtfwsMrqvLCH1JWgYY3FBnEbp5TloWmHkSoSFxLwOGyF21VdCJ2lSnEbpXB+HwAIyAC5DwuULtGbgC49i1qwEnIAUsAHuvGL1f1ZV/8ih/H5tKB7ujLqpcvQA8quvcz0G5A7oT9DYx6XLOAAAAAElFTkSuQmCC');
    }

    &.details-button {
        position: relative;
        min-width: 25rem;
        border: 1px solid $orange;
        text-align: left;
        color: #fff;
        background-color: transparent;
        padding: 1.2rem 5rem 1.2rem 3rem;
    }

    &.search-button {
        position: relative;
        min-width: 25rem;
        border: 1px solid $orange;
        text-align: left;
        color: #fff;
        background-color: transparent;
        padding: 1rem 5rem 1rem 3rem;
    }

    &.library-search-button {
        height: 4em;
        width: 100%;
        min-width: 0px;
        background-color: $orange;
        padding: 1rem 0rem 1rem 1rem;
    }

    &.submit {
        position: relative;
        height: 55px;
        width: 260px;
        border: 1px solid $orange;
        text-align: left;
        color: #fff;
        background-color: transparent;
    }

    &.contact-cta {
        position: relative;
        height: 55px;
        width: 200px;
        border: none;
        text-align: left;
        color: #fff;
        background-color: transparent;
        padding-left: 30px;
        padding: 16px 50px 16px 25px;
    }

    &:hover {
        background-color: $orange;
    }
}
